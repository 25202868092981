<template>
  <div>
    <c-card class="cardClassDetailForm" title="상세">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" /> -->
            <c-btn v-if="editable&&!disabled" label="저장" icon="save" @btnClicked="saveYear" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="lawMakingCheck"
            deptValue="managerDeptCd"
            type="dept_user"
            label="담당자"
            name="managerId"
            v-model="lawMakingCheck.managerId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-dept-multi 
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            :parentCheckDepts="lawMakingCheck.acceptDeptCds" 
            label="적용부서" 
            name="acceptDeptCds" 
            v-model="lawMakingCheck.acceptDeptCds" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="해당시설"
            name="applicableFacility"
            v-model="lawMakingCheck.applicableFacility">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4"></div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="현행 법규내용 요약"
            name="currentLawContents"
            v-model="lawMakingCheck.currentLawContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="개정 법규내용 요약"
            name="reLawContents"
            v-model="lawMakingCheck.reLawContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="검토요청내용"
            name="reviewRequestContents"
            v-model="lawMakingCheck.reviewRequestContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="비고"
            name="remark"
            v-model="lawMakingCheck.remark">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card class="cardClassDetailForm q-mt-sm q-mb-sm" noHeader>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-text
                :editable="editable"
                :readonly="true"
                label="입법예고명"
                name="isNm"
                v-model="lawMakingCheck.isNm">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="법령종류(소관부처)"
                name="isClsAsndOfiNm"
                v-model="lawMakingCheck.isClsAsndOfiNm">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="공고번호(공고일자)"
                name="pntc"
                v-model="lawMakingCheck.pntc">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="기간"
                name="period"
                v-model="lawMakingCheck.period">
              </c-text>
            </div>
            <div class="col-12">
              <q-chip color="teal" text-color="white" icon="download" outline square>법령안</q-chip>
              <q-btn dense flat color="primary" :label="lawMakingCheck.fileName" @click="fileDown" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="검토자 목록"
      :columns="grid.columns"
      :data="lawMakingCheck.checkers"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="checkUserId"
    >
      <!-- @linkClick="linkClick" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="제외" icon="remove" @btnClicked="deleteChecker" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addChecker" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-making-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmLawMakingCheckId: '',
        mdmLawMakingId: '',
        row: null,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      lawMakingCheck: {
        /** 입법예고검토 정보 */
        mdmLawMakingCheckId: '',  // 입법예고검토 번호
        saiLawMakingStepCd: '',  // 진행단계
        managerId: '',  // 담당자id
        acceptDeptCds: '',  // 적용부서
        currentLawContents: '',  // 현행 법규내용 요약
        reLawContents: '',  // 개정 법규내용 요약
        applicableFacility: '',  // 해당시설
        reviewRequestContents: '',  // 검토요청내용
        remark: '',  // 비고
        /** 입법예고 정보 */
        mdmLawMakingId: '',  // 입법예고 번호
        ogLmPpSeq: '',  // 입법예고 일련번호
        isNm: '',  // 입법예고명
        isClsNm: '',  // 법령종류
        mgrGovcd: '',  // 소관부처 코드
        asndOfiNm: '',  // 소관부처
        isClsAsndOfiNm: '', // 법령종류(소관부처)
        pntcNo: '',  // 공고번호
        pntcDt: '',  // 공고일자
        pntc: '',  // 공고번호(공고일자)
        stYd: '',  // 시작일자
        edYd: '',  // 종료일자
        period: '',  // 기간
        fileName: '',  // 파일명
        fileDownLink: '',  // 파일다운로드 링크
        readCnt: '',  // 조회수
        mappingLbicId: '',  // 법안 매핑 번호
        announceType: '',  // 공고 종류
        detailUrl: '',  // 입법상세링크
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checkers: [], // 검토자들
      },
      grid: {
        columns: [
          {
            name: 'checkDeptName',
            field: 'checkDeptName',
            label: '부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'checkUserName',
            field: 'checkUserName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '검토의견',
            align: 'left',
            style: 'width:400px',
            sortable: true,
            type: 'textarea'
          },
        ],
        data: [],
      },
      attachInfo: {
        isSubmit: '', // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: 'MOC_REQUEST',
        taskKey: '',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return false
    },
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 550) + 'px' : '500px'
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.review.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.row) {
        this.$set(this.$data, 'lawMakingCheck', this.popupParam.row)
      }
      this.$set(this.lawMakingCheck, 'checkers', [
        { checkDeptCd: 'D10003', checkDeptName: '안전환경팀', checkUserId: 'U000000018', checkUserName: '김이름', },
        { checkDeptCd: 'D10003', checkDeptName: '안전환경팀', checkUserId: 'U000000019', checkUserName: '이이름', },
        { checkDeptCd: 'D10003', checkDeptName: '안전환경팀', checkUserId: 'U000000020', checkUserName: '최이름', },
      ]);
      this.$set(this.lawMakingCheck, 'managerId', 'U000000001')
      this.$set(this.lawMakingCheck, 'acceptDeptCds', 'D10001,D10003,D10005')
      this.$set(this.lawMakingCheck, 'currentLawContents', '')
      this.$set(this.lawMakingCheck, 'reLawContents', '')
      this.$set(this.lawMakingCheck, 'applicableFacility', '시설A, 시설B')
      this.$set(this.lawMakingCheck, 'reviewRequestContents', '')
      this.$set(this.lawMakingCheck, 'remark', '')
    },
    fileDown() {
      let link = document.createElement('a');
      link.href = this.lawMakingCheck.fileDownLink;
      link.download = this.lawMakingCheck.fileName;
      link.click();
    },
    saveYear() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          // this.$_.forEach(this.node.hazopScenarios, item => {
          //   item.regUserId = this.$store.getters.user.userId
          //   item.chgUserId = this.$store.getters.user.userId
          // })
          
          // this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addChecker() {
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.lawMakingCheck.checkers, { checkUserId: item.userId }) === -1) {
            _data.push({
              checkDeptCd: item.deptCd, checkDeptName: item.deptName, 
              checkUserId: item.userId, checkUserName: item.userName,
              remark: '',
              finishFlag: 'N',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.lawMakingCheck.checkers = this.$_.concat(this.lawMakingCheck.checkers, _data)
      }
    },
    deleteChecker() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '제외하시겠습니까?', 
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>