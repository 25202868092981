var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "저장", icon: "save" },
                        on: { btnClicked: _vm.saveYear },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    data: _vm.lawMakingCheck,
                    deptValue: "managerDeptCd",
                    type: "dept_user",
                    label: "담당자",
                    name: "managerId",
                  },
                  model: {
                    value: _vm.lawMakingCheck.managerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "managerId", $$v)
                    },
                    expression: "lawMakingCheck.managerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-dept-multi", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    parentCheckDepts: _vm.lawMakingCheck.acceptDeptCds,
                    label: "적용부서",
                    name: "acceptDeptCds",
                  },
                  model: {
                    value: _vm.lawMakingCheck.acceptDeptCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "acceptDeptCds", $$v)
                    },
                    expression: "lawMakingCheck.acceptDeptCds",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" }),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "현행 법규내용 요약",
                    name: "currentLawContents",
                  },
                  model: {
                    value: _vm.lawMakingCheck.currentLawContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "currentLawContents", $$v)
                    },
                    expression: "lawMakingCheck.currentLawContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "개정 법규내용 요약",
                    name: "reLawContents",
                  },
                  model: {
                    value: _vm.lawMakingCheck.reLawContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "reLawContents", $$v)
                    },
                    expression: "lawMakingCheck.reLawContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "검토요청내용",
                    name: "reviewRequestContents",
                  },
                  model: {
                    value: _vm.lawMakingCheck.reviewRequestContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "reviewRequestContents", $$v)
                    },
                    expression: "lawMakingCheck.reviewRequestContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "비고",
                    name: "remark",
                  },
                  model: {
                    value: _vm.lawMakingCheck.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.lawMakingCheck, "remark", $$v)
                    },
                    expression: "lawMakingCheck.remark",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm q-mt-sm q-mb-sm",
          attrs: { noHeader: "" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "입법예고명",
                          name: "isNm",
                        },
                        model: {
                          value: _vm.lawMakingCheck.isNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawMakingCheck, "isNm", $$v)
                          },
                          expression: "lawMakingCheck.isNm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "법령종류(소관부처)",
                          name: "isClsAsndOfiNm",
                        },
                        model: {
                          value: _vm.lawMakingCheck.isClsAsndOfiNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawMakingCheck, "isClsAsndOfiNm", $$v)
                          },
                          expression: "lawMakingCheck.isClsAsndOfiNm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "공고번호(공고일자)",
                          name: "pntc",
                        },
                        model: {
                          value: _vm.lawMakingCheck.pntc,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawMakingCheck, "pntc", $$v)
                          },
                          expression: "lawMakingCheck.pntc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          readonly: true,
                          label: "기간",
                          name: "period",
                        },
                        model: {
                          value: _vm.lawMakingCheck.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.lawMakingCheck, "period", $$v)
                          },
                          expression: "lawMakingCheck.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "q-chip",
                        {
                          attrs: {
                            color: "teal",
                            "text-color": "white",
                            icon: "download",
                            outline: "",
                            square: "",
                          },
                        },
                        [_vm._v("법령안")]
                      ),
                      _c("q-btn", {
                        attrs: {
                          dense: "",
                          flat: "",
                          color: "primary",
                          label: _vm.lawMakingCheck.fileName,
                        },
                        on: { click: _vm.fileDown },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "검토자 목록",
            columns: _vm.grid.columns,
            data: _vm.lawMakingCheck.checkers,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "checkUserId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.deleteChecker },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addChecker },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }